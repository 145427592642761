<template>
  <div class="category-tree">
    <div v-if="defaultName && (!can_edit || disabled) && !show">
      <span>{{ defaultName }}</span>
      <span v-if="!disabled" class="text-info" @click="can_edit = true">
        {{ $t("Modify") }}
      </span>
    </div>
    <div v-show="(can_edit && !disabled) || show">
      <a-cascader
        :show-search="{ filter }"
        :options="options"
        :fieldNames="{
          label: 'name',
          value: 'categoryId',
          children: 'children',
        }"
        placeholder="Please select"
        @change="onChange"
        @popupVisibleChange="popupVisibleChange"
        :disabled="disabled"
        v-model="cascaderValue"
        style="width: 100%"
      />
      <span
        v-if="!disabled && !show"
        class="text-info"
        @click="can_edit = false"
      >
        {{ $t("Cancel") }}
      </span>
    </div>
  </div>
</template>

<script>
import http from "@common/http";
import api from "@common/api";
import { Cascader } from "ant-design-vue";
export default {
  //lazada分类树
  name: "categoryTree",
  props: ["disabled", "defaultName", "defaultValue", "show", "type"],
  props: {
    defaultName: {
      type: [String, Number],
    },
    defaultValue: {
      type: Array,
      default: () => {
        return [];
      },
    },
    show: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "LAZADA",
    },
  },
  data() {
    return {
      options: [],
      can_edit: false,
      cascaderValue: [],
    };
  },
  components: {
    "a-cascader": Cascader,
  },
  mounted() {
    this.queryPlatformCategoryTree();
    if (this.defaultValue) {
      this.$nextTick(() => {
        this.cascaderValue = this.defaultValue;
      });
    }
  },
  methods: {
    filter(inputValue, path) {
      return path.some(
        (option) =>
          option.name.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
      );
    },
    clear() {
      this.cascaderValue = [];
    },
    queryPlatformCategoryTree() {
      let list;

      switch(this.type) {
        case "LAZADA":
          list = localStorage.getItem("lazadaCategoryList")
          break;
        case "MOOMALL":
          list = localStorage.getItem("moomallCategoryList");
          break;
        case "SHOPSPADE":
          list =localStorage.getItem("shopspadeCategoryList");
          break;
        case "SHOPEE":
          list = localStorage.getItem("shopeeCategoryList")
          break;
        case "YOUPIK":
          list = localStorage.getItem("youpikCategoryList")
          break;
        default:
          list = localStorage.getItem("lazadaCategoryList")
          return;
      }

      if (list) {
        this.options = JSON.parse(list);
        return;
      }
      http({
        instance: this,
        type: "post",
        url: api.queryPlatformCategoryTree,
        hasLoading: true,
        data: {},
        success: (res) => {
          let list = res.result.platformCategoryTree;
          localStorage.setItem(
            "lazadaCategoryList",
            JSON.stringify(list.LAZADA)
          );
          localStorage.setItem(
            "moomallCategoryList",
            JSON.stringify(list.MOOMALL)
          );
          localStorage.setItem(
            "shopspadeCategoryList",
            JSON.stringify(list.SHOPSPADE)
          );
          localStorage.setItem(
            "shopeeCategoryList",
            JSON.stringify(list.SHOPEE)
          );
          localStorage.setItem(
            "youpikCategoryList",
            JSON.stringify(list.YOUPIK)
          );
          this.options = list[this.type];
        },
      });
    },
    onChange(value, selectedOptions) {
      this.cascaderValue = value;
      let text = selectedOptions
        ? selectedOptions.map((o) => o.name).join(", ")
        : "";
      this.$emit("changeCategory", { text, value });
    },
    popupVisibleChange(value) {
      this.$nextTick(() => {
        this.$emit("closeTree", !value);
      });
    },
  },
};
</script>

<style scoped>
.text-info {
  cursor: pointer;
  margin-left: 10px;
}
</style>
