<template>
  <div class="flex">
    <a-cascader
      :field-names="{ label: 'categoryName', value: 'categoryId', children: 'children' }"
      :options="category_list"
      v-model="value"
      :disabled="disabled"
      :show-search="{ filter }"
      :placeholder="$t(`请输入`) + '/' + $t(`选择`) + $t(`商品类目`)"
      @change="onChange"
      style="width: 100%"
    />
    <a-button
      style="margin-left: 5px"
      type="primary"
      v-if="!disabled && !canNotAdd"
      @click="visiable = true"
    >
      {{ $t('新增类目') }}
    </a-button>
    <a-modal width="800px" v-model="visiable" :title="$t('添加规格')" @ok="createCategory">
      <a-form-model>
        <a-form-model-item :label="$t('请输入类目名称')" required>
          <a-input v-model="modalData.categoryName"></a-input>
        </a-form-model-item>
        <a-form-model-item :label="$t(`类目`)" required>
          <categoryTree
            ref="categoryTree"
            :show="true"
            @changeCategory="changeCategory"
          ></categoryTree>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import http from '@common/http'
import api from '@common/api'
import categoryTree from '@component/categoryTree'
export default {
  //本地分类列表
  name: 'categoryList',
  props: ['placeholder', 'defaultValue', 'disabled', 'canNotAdd'],
  components: {
    categoryTree,
  },
  data() {
    return {
      category_list: [],
      value: [],
      visiable: false,
      modalData: {
        categoryName: '',
        categoryId: '',
      },
    }
  },
  mounted() {
    this.queryCategoryList()
  },
  methods: {
    clear() {
      this.value = []
    },
    queryCategoryList() {
      return new Promise((resolve) => {
        http({
          instance: this,
          type: 'post',
          url: api.queryCategoryList,
          data: {},
          success: (res) => {
            this.category_list = res.result.categoryList
            this.setDefaultValue(this.defaultValue)
            resolve(res)
          },
        })
      })
    },
    onChange(value, selectedOptions) {
      if (selectedOptions?.length > 0) {
        let data = selectedOptions[selectedOptions.length - 1]
        this.value = value
        this.$emit('handleCategoryChange', data)

        this.$nextTick(() => {
          this.$emit('changeList', 'speed')
        })
      } else {
        this.$emit('handleCategoryChange')
      }
    },
    filter(inputValue, path) {
      return path.some(
        (option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
      )
    },
    createCategory() {
      if (!this.modalData.categoryName) {
        this.$warning(this.$t(`请输入规格名`))
        return
      }
      if (!this.modalData.categoryId) {
        this.$warning(this.$t(`请选择对应Lazada规格`))
        return
      }
      http({
        instance: this,
        type: 'post',
        hasLoading: true,
        url: api.createCategory,
        data: {
          categoryName: this.modalData.categoryName,
          lazadaCategoryId: this.modalData.categoryId,
        },
        success: (res) => {
          this.$emit('createCategory', {
            categoryName: this.modalData.categoryName,
            categoryId: res.result.categoryId,
          })
          this.queryCategoryList()
          this.$success(this.$t(`创建成功`))
          this.modalData.categoryName = ''
          this.modalData.categoryId = ''
          this.$refs.categoryTree.clear()
          this.visiable = false
        },
      })
    },
    changeCategory(data) {
      if (data.value.length) {
        this.modalData.categoryId = data.value[data.value.length - 1]
      } else {
        this.modalData.categoryId = ''
      }
    },
    setDefaultValue(val) {
      if (val && this.category_list.length > 0) {
        let parentItem = this.category_list.find((item) => {
          return item.categoryId === val
        })
        if (parentItem) {
          this.value = [val]
        } else {
          this.category_list.forEach((item) => {
            item.children.forEach((elem) => {
              if (elem.categoryId == val) {
                this.value = [item.categoryId, val]
              }
            })
          })
        }
      } else {
        this.value = []
      }
    },
  },
  watch: {
    defaultValue(val) {
      // console.log('val: ', val)
      this.setDefaultValue(val)
    },
  },
}
</script>

<style scoped lang="scss">
.flex {
  position: relative;
  align-items: center;
}
.category-text {
  width: 100px;
  padding-left: 10px;
  cursor: pointer;
  position: absolute;
  right: 0;
  transform: translateX(100%);
}
</style>
